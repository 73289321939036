import { yupResolver } from '@hookform/resolvers/yup';
import { DataGrid, GridColumns, GridRowParams } from '@material-ui/data-grid';
import React, { useEffect, useState, useContext, ChangeEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { MdDeleteForever } from 'react-icons/md';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DefaultLoader from '~/components/DefaultLoader';
import FormDefault from '~/components/FormDefault';
import { BuscaProduto, InputText } from '~/components/NovosInputs';
import Search from '~/components/Search';
import Separator from '~/components/Separator';
import api from '~/services/api';

import { FormSimilar, Produto, schemaSimilar } from './protocols';
import { ButtonRow, Container } from './styles';
import { loadingContext } from '~/context/loading';

const MySwal = withReactContent(Swal);

const Similar: React.FC = () => {
  const { setLoading } = useContext(loadingContext);

  const formSimilarBlank: FormSimilar = {
    cod_similar: {
      value: undefined,
      isRequired: false,
      isInvalid: false,
    },
    des_similar: {
      value: '',
      isRequired: true,
      isInvalid: true,
    },
    produtos: {
      produtos: [],
      isRequired: false,
      isInvalid: false,
    },
  };

  const [formSimilar, setFormSimilar] = useState<FormSimilar>(formSimilarBlank);
  const [init, setInit] = useState(false);

  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);

  const [produtos, setProdutos] = useState<Produto[]>([]);

  const [page, setPage] = useState(0);

  const {
    register,
    setValue,
    control,
    reset,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schemaSimilar),
  });

  useEffect(() => {
    setInit(false);
  }, [formSimilar]);

  useEffect(() => {
    const data = formSimilar.produtos.produtos.map((produto: Produto) => {
      return {
        id: produto.cod_produto,
        cod_produto: produto.cod_produto,
        des_produto: produto.des_produto,
      };
    });
    setProdutos(data);
  }, [formSimilar.produtos.produtos]);

  const tabColumns: GridColumns = [
    {
      field: 'cod_produto',
      headerName: 'Código',
      width: 150,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'des_produto',
      headerName: 'Descrição',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: '',
      headerName: 'Ação',
      sortable: false,
      width: 150,
      headerAlign: 'center',
      cellClassName: 'super-app-theme--cell',
      headerClassName: 'super-app-theme--header',
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        const onRemove = () => {
          handleRemoveProduto(row.cod_produto);
        };
        return (
          <>
            <ButtonRow
              type="button"
              onClick={onRemove}
              style={{ width: '100%' }}
            >
              <MdDeleteForever size={20} style={{ color: '#e63c3c' }} /> Remover
            </ButtonRow>
          </>
        );
      },
    },
  ];

  /**
   * Validações
   */

  const validaInputsParaSubmit = (inputs: FormSimilar): boolean => {
    for (let i = 0; i < Object.keys(inputs).length; i++) {
      if (
        formSimilar[Object.keys(inputs)[i] as keyof FormSimilar].isRequired &&
        formSimilar[Object.keys(inputs)[i] as keyof FormSimilar].isInvalid
      ) {
        return false;
      }
    }
    return true;
  };

  /**
   * Evento disparado ao clicar em uma linha da tabela
   * @param param
   */
  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    setUpdate(true);

    const { cod_similar, des_similar } = row;
    const res = await api.get(`/produto-similar/${cod_similar}`);
    const { success, data, message } = res.data;

    if (!success) throw new Error(message);
    const options = data.map((produto: Produto) => ({
      id: produto.cod_produto,
      des_produto: produto.des_produto,
      cod_produto: produto.cod_produto,
    }));

    setValue('des_similar', des_similar);

    setFormSimilar({
      ...formSimilar,
      cod_similar: {
        ...formSimilar.cod_similar,
        value: cod_similar,
      },
      des_similar: {
        ...formSimilar.des_similar,
        value: des_similar,
        isRequired: false,
        isInvalid: false,
      },
      produtos: {
        ...formSimilar.produtos,
        produtos: options,
      },
    });

    setLoader(false);

    setShowSearch(false);
  };

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = () => {
    setUpdate(false);
    reset();
    setFormSimilar(formSimilarBlank);
  };

  const onNew = () => {
    setShowSearch(false);
    resetFormData();
  };

  const handleAddProduto = (value: Produto[]) => {
    const { produtos: prods } = formSimilar.produtos;

    if (prods.some((produto) => produto.cod_produto === value[0].cod_produto))
      return toast.warning(
        'Produto já relacionado na lista de produtos vinculados.',
      );

    const produtosAdd = [...prods, ...value].sort((a, b) =>
      a.des_produto.localeCompare(b.des_produto),
    );

    setValue('produtos', produtosAdd);

    setFormSimilar((prevForm) => ({
      ...prevForm,
      produtos: {
        ...prevForm.produtos,
        produtos: produtosAdd,
      },
    }));
  };

  const handleRemoveProduto = (cod_produto: string) => {
    const { produtos: prods } = formSimilar.produtos;

    const produtosAdd = prods
      .filter((produto) => produto.cod_produto !== cod_produto)
      .sort((a, b) => a.des_produto.localeCompare(b.des_produto));

    setValue('produtos', produtosAdd);

    setFormSimilar((prevForm) => ({
      ...prevForm,
      produtos: {
        ...prevForm.produtos,
        produtos: produtosAdd,
      },
    }));
  };

  const verifyProducts = async (formProdutos: Produto[]) => {
    const res = await api.post('/similar/check-produtos', {
      cod_similar: formSimilar.cod_similar.value,
      produtos: formProdutos,
    });

    const { exists, message } = res.data;

    return {
      exists,
      message,
    };
  };

  const upsertSimilar = async () => {
    const upsert = {
      des_similar: formSimilar.des_similar.value.trim(),
      produtos: formSimilar.produtos.produtos,
    };
    try {
      setLoading(true);
      if (isUpdate) {
        const res = await api.put(
          `/similar/${formSimilar.cod_similar.value}`,
          upsert,
        );
        const { message } = res.data;
        toast.success(message);
        setShowSearch(true);
        return resetFormData();
      }
      const res = await api.post('/similar', upsert);
      const { message } = res.data;
      toast.success(message);
      return resetFormData();
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const desSimilar = getValues('des_similar') as string;

    if (desSimilar.length <= 0 || desSimilar === '') {
      setError('des_similar', { type: 'required' });
      return;
    }

    clearErrors('des_similar');

    if (!validaInputsParaSubmit(formSimilar)) {
      setInit(true);
      return toast.warning('Há informações pendentes.');
    }

    setInit(false);

    const productsWithSimilar = await verifyProducts(
      formSimilar.produtos.produtos,
    );
    if (productsWithSimilar?.exists) {
      setLoading(false);
      MySwal.fire({
        html: `${productsWithSimilar?.message}`,
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Confirmar',
        cancelButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          return upsertSimilar();
        }
      });
    } else {
      return upsertSimilar();
    }
  };

  const handleDeleteSimilar = async () => {
    const res = await api.delete(`/similar/${formSimilar.cod_similar.value}`);
    const { success, message } = res.data;
    if (success) {
      resetFormData();
      setShowSearch(true);
      return toast.success(message);
    }
  };

  if (loader) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <Container>
      {showSearch && (
        <Search codTela={29} newData={onNew} onRowClick={onRowClick} />
      )}

      {!showSearch && (
        <FormDefault
          codTela={29}
          title="Cadastro de Similar"
          onSave={handleSubmit}
          codigoRegistro={[
            {
              value: formSimilar.cod_similar.value,
              des_campo: 'Código',
            },
          ]}
          onCancel={() => setShowSearch(true)}
          isUpdate={isUpdate}
          onNew={onNew}
          onDelete={handleDeleteSimilar}
          onClearFields={resetFormData}
          onReturnSearch={() => {
            setShowSearch(true);
            resetFormData();
          }}
        >
          <Row>
            <Col sm={12} md={12}>
              <InputText
                label="Descrição"
                maxLength={50}
                placeholder="Informe a Descrição"
                name="des_similar"
                register={register}
                control={control}
                isError={!!errors.des_similar}
                onInput={(ev: ChangeEvent<HTMLInputElement>) => {
                  const { value } = ev.target;

                  setValue('des_similar', value.toUpperCase());

                  setFormSimilar({
                    ...formSimilar,
                    des_similar: {
                      ...formSimilar.des_similar,
                      value: value.toUpperCase(),
                      isInvalid: value.trim() === '',
                    },
                  });
                }}
              />
            </Col>
          </Row>
          <Separator labelText="Relação de Produtos Vinculados" />
          <Row>
            <Col sm={12} md={12}>
              <BuscaProduto
                label="Buscar Produto"
                placeholder="Pesquisar"
                name="produtos"
                register={register}
                isError={!!errors.produtos}
                control={control}
                buscaNasLojas={[1]}
                customOptions={{
                  hideBuscarPor: true,
                  buscarPor: ['Produto'],
                  showSelecionarItensContendo: true,
                }}
                getProduct={(selected: any) => {
                  const data = selected.map((item: any) => ({
                    cod_produto: item.cod_produto as string,
                    des_produto: item.des_produto as string,
                  }));
                  handleAddProduto(data);
                }}
              />
            </Col>
            <Col
              sm={12}
              md={12}
              className="mt-3 w-100"
              style={{
                height: '400px',
              }}
            >
              <DataGrid
                rows={produtos}
                pageSize={5}
                disableColumnMenu
                columns={tabColumns}
                page={page}
                onPageChange={handleChangePage}
                localeText={{
                  noRowsLabel: 'Nenhum registro encontrado...',
                  columnMenuLabel: 'Menu',
                  columnMenuFilter: 'Filtrar',
                  columnMenuHideColumn: 'Esconder',
                  columnMenuUnsort: 'Não ordenar',
                  columnMenuSortAsc: 'Ordernar ASC',
                  columnMenuSortDesc: 'Ordernar DESC',
                  columnMenuShowColumns: 'Mostrar columnas',
                }}
              />
            </Col>
          </Row>
        </FormDefault>
      )}
    </Container>
  );
};

export default Similar;
